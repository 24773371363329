
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import ArticleList from '@/components/articles/ArticleList.component.vue';
  import { Article, ArticleImageDto } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Utils } from '@/scripts/Utils';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import ArticleFilter from '@/components/filtering/ArticleFilter.component.vue';
import { ArticleFiltersDto } from '@/interfaces/ArticleFiltersDto';


/**
 * @desc page d'affichage de tous les articles non archivés et validés
 */
  @Component({
    components: {
      'article-list' : ArticleList,
      'article-filter' : ArticleFilter
    },
  })
  export default class ArticlesView extends Vue {
    allArticles : Array<Article> = [];
    stopLoading= false;
    loadArticlesCount = 25;

    filteringData : ArticleFiltersDto = new ArticleFiltersDto();

    errorMsg = '';

    mounted()  : void {
      this.$root.$on('filtersUpdate', this.filtersUpdate);
      this.init();
    }

    init(): void {
      this.filteringData.searchText = undefined;
      this.filteringData.rubrics = undefined;
      this.filteringData.years = undefined;
      this.filteringData.unarchived = undefined;
      this.filtersUpdate(this.filteringData);
    }

    beforeDestroy(): void {
      this.$root.$off("filtersUpdate", this.filtersUpdate);
    }

    /**
     * @desc renvoie la date correspondant au lendemain à 00:00
     */
    private nextDay(date : Date) : Date {
      date = new Date(date.getTime() + 24*3600*1000);
      date.setHours(0);
      date.setSeconds(0);
      date.setMinutes(0);
      return date;
    }

    public requestArticlesLoading(lastDate: Date): void {
      if(this.filteringData.wantedEndDate && this.filteringData.wantedEndDate < lastDate) {
        this.filteringData.endDate = new Date(this.filteringData.wantedEndDate);
        this.filteringData.endDate = this.nextDay(this.filteringData.endDate);
      }
      else
        this.filteringData.endDate = lastDate;
      this.loadAllArticles(this.filteringData, false);
    }

    public filtersUpdate(filteringData: ArticleFiltersDto): void {
      this.filteringData = filteringData;
      this.filteringData.endDate = filteringData.wantedEndDate;
      if(this.filteringData.endDate) {
        this.filteringData.endDate = this.nextDay(this.filteringData.endDate);
      }
      this.stopLoading = false;
      this.loadAllArticles(this.filteringData, true);
    }

    /**
     * @desc charge les articles limités au nombre "loadArticlesCount" plus anciens que la date "lastDate"
     */
    loadAllArticles(filteringData : ArticleFiltersDto, reload : boolean) : void {
      filteringData.limit = this.loadArticlesCount;

      if(this.stopLoading) return;

      const headers = AuthenticationService.getRequestHeader();
      const query = '?' + Utils.createUrlQuery(filteringData);

      this.stopLoading = true;
      axios
        .get(  UrlConsts.getAllArticlesFiltered + query, headers)
        .then((response) => {
          const responseArray = response.data;
          if(responseArray && !responseArray.length) {
            this.errorMsg = 'Aucun article ne correspond à votre recherche...';
          }
          else{
            this.errorMsg = '';
          }

          if(!responseArray || !responseArray.length || responseArray.length < this.loadArticlesCount) {
            this.stopLoading = true;
          }
          else {
            setTimeout(() => {
              this.stopLoading = false;
            }, 50);
          }
          if(reload) this.allArticles = [];
          this.allArticles = this.allArticles.filter((x: Article) => !responseArray.find((y : any) => y.id === x.id));
          this.allArticles = this.allArticles.concat(responseArray.map((x: any) => new Article(x)));
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc demande l'affichage du menu de filtrage // affichage mobile uniquement
     */
    showFilterMenu() : void {
      this.$root.$emit('show-filter-menu', true);
    }
  }
